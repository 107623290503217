import React from "react";

const GlossaryAsideMenu = ({
  terms,
  selectedItemId,
  linkBase,
  onLinkClick,
  YTranslate
}) => {
  return (
    <aside
      className="menu platform-menu"
      style={{ transform: `translate(0, ${YTranslate || 0 }px)` }}
    >
      <ul className="menu-list">
        {terms.map((t, i) => (
          <li
            key={i}
            className={`top-level-item is-black`}
          >
            <a
              className="top-level-title-container"
              href={`${linkBase}${t.slug}`}
              onClick={(e) => { if (onLinkClick) onLinkClick(e, t) }}
            >
              <span className={`top-level-title ${t.id === selectedItemId ? 'selected' : ''}`} >
                {t.title}
              </span>
            </a>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default GlossaryAsideMenu;
