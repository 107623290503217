import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { debounce } from "lodash-es";

import Layout from "../components/layout/Layout";
import GlossaryAsideMenu from "../components/GlossaryAsideMenu";

const IntegrationVocabulary = ({ data, location }) => {
  const terms = data.allContentfulDefinedTerm.nodes;

  const [selectedTerm, setSelectedTerm] = useState(null);
  const [sideMenuYTranslate, setSideMenuYTranslate] = useState(0);

  useEffect(() => {
    const handleWindowScroll = debounce(() => {
      const topHeight = 360;
      if (window.scrollY < topHeight) {
        setSideMenuYTranslate(0);
      }
      else {
        const sideMenuItemHeight = 56;
        const itemPreviewHeight = 206;
        setSideMenuYTranslate(-sideMenuItemHeight * Math.round((window.scrollY - topHeight) / itemPreviewHeight));
      }
    }, 10);

    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  const handleMenuItemsClick = (e, term) => {
    e.preventDefault();

    setSelectedTerm(term);

    const elToScrollTo = `#${term.slug}`;
    const el = document.querySelector(elToScrollTo);
    if (el) {
      const headerOffset = 90;
      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });

      const hrefParts = window.location.href.split('#');
      window.history.pushState({}, null, `${hrefParts[0]}${elToScrollTo}`);
    }
  }

  const getGlossaryStructuredData = () => {
    return JSON.stringify([
			{
        "@context": "https://schema.org/"
      },
      {
        "@type": ["DefinedTermSet","Frends integration terms"],
        "@id": "https://frends.com/glossary",
        "name": "Frends integration glossary"
      },
      ...terms.map((t) => {
        return {
          "@type": ["DefinedTerm", "Frends integration term"],
					"@id": `https://frends.com/glossary/${t.slug}`,
					"name": t.title,
					"inDefinedTermSet": "https://frends.com/glossary"
        }
      })
    ]);
  }

  return (
    <Layout
      title="Glossary"
      pageContentId="glossary-content"
      location={location}
      structuredData={getGlossaryStructuredData()}
    >
      <main id="glossary-content" className="section">
        <div className="container">
          <div className="is-hidden-desktop">
            <GlossaryContentSection
              terms={terms}
              idPrefix="mobile_"
            />
          </div>

          <div className="is-hidden-touch columns is-multiline margin-y-40">
            <div
              className="column is-4"
              style={{
                position: 'sticky',
                top: '80px',
                alignSelf: 'flex-start',
                overflow: 'hidden',
              }}
            >
              <GlossaryAsideMenu
                terms={terms}
                selectedItemId={selectedTerm ? selectedTerm.id : ''}
                linkBase="/glossary#"
                onLinkClick={(e, t) => handleMenuItemsClick(e, t)}
                YTranslate={sideMenuYTranslate}
              />
            </div>
            <div className="column is-8">
              <GlossaryContentSection
                terms={terms}
                idPrefix=""
              />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

const GlossaryContentSection = ({
  terms,
  idPrefix,
}) => {
  return (
    <section className="search-results-section-content content">
      <h1 className="is-orange size-40">
        Glossary
      </h1>

      <p className="is-black has-text-weight-bold">
        IT and integration areas have lots of terms and abbreviations. Here are the definitions most commonly used in the integration area. These blog posts and articles are linked to these terms for your convenience.
      </p>

      <div className="margin-top-70">
        {terms.map((t, i) => (
          <GlossaryContentLink
            key={i}
            term={t}
            idPrefix={idPrefix}
          />
        ))}
      </div>
    </section>
  );
}

const GlossaryContentLink = ({
  term,
  idPrefix,
}) => {
  return (
    <a href={`/glossary/${term.slug}`} >
      <article
        className="block"
        id={`${idPrefix}${term.slug}`}
        style={{
          scrollPaddingTop: '80px',
          minHeight: '133px'
        }}
      >
        <h2 className="is-orange">
          {term.title}
        </h2>

        <div
          className="margin-top-30 clamp-shown-lines"
          dangerouslySetInnerHTML={{ __html: term.description.childMarkdownRemark.html }}
        />
      </article>
    </a>
  );
}

export const query = graphql`
  query VocabularyQuery {
    allContentfulDefinedTerm(
      filter: {node_locale: {eq: "en-US"}}
      sort: {order: ASC, fields: title}
    ) {
      nodes {
        id
        slug
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default IntegrationVocabulary;
